import * as React from 'react';

import history from '../../images/dfh-timeline-2024.png';
import historyMobile from '../../images/DFH-timeline-mobile-2024.png';

const OurHistory: React.FC = () => {
	return (
		<>
			<section className={'bg-white px-5'}>
				<div
					className={
						'container mx-auto flex flex-col gap-4 pb-12 pt-12'
					}
				>
					<h2 className="py-8 text-center font-kiona text-4xl font-normal text-secondary max-sm:py-4 max-sm:text-3xl">
						OUR HISTORY
					</h2>
					<p className="m-0 mx-auto max-w-[850px] font-lato text-2xl uppercase leading-tight tracking-tight text-[#13334D] max-sm:text-xl">
						The power of a national home builder, focused on serving
						home buyers and building the dream, one home at a time.
					</p>
					<p className="m-0 mx-auto max-w-[850px] font-lato text-lg leading-tight tracking-tight text-[#13334D]">
						Since our founding in 2008, Dream Finders Homes has
						vowed to deliver the highest possible standards in new
						homes.
					</p>
					<p className="m-0 mx-auto max-w-[850px] font-lato text-lg leading-tight tracking-tight text-[#13334D]">
						We are committed to providing exceptional customer
						service while delivering a beautifully innovative home
						for you and your family. And you get all of this at the
						best value possible. Across{' '}
						<strong>
							10 states, 235 unique communities, and over 35,100
							homes closed
						</strong>
						, our most important home buyer is still just one
						person...YOU. We look forward to sharing The{' '}
						<strong>
							<i>Dream Finders Difference</i>
						</strong>{' '}
						with you.
					</p>
				</div>
			</section>
			<section className="bg-white px-4 pb-12">
				<div className="mx-auto">
					<picture>
						<source srcSet={history} media="(min-width: 900px)" />
						<img
							src={historyMobile}
							alt="A description of the image."
							className="mx-auto"
						/>
					</picture>
				</div>
			</section>
		</>
	);
};

export default OurHistory;
